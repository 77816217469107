import { signal } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

export type TableQueryParams = Partial<NzTableQueryParams & { search: string }>;

export function createQueryParamsSignal(queryParams: Partial<TableQueryParams> = {}) {
  return signal<TableQueryParams>({
    search: '',
    pageIndex: 1,
    pageSize: 30,
    filter: [],
    sort: [],
    ...queryParams,
  });
}

export function mapToSort(queryParams: TableQueryParams) {
  const orderMap = {
    ascend: 'asc',
    descend: 'desc',
  } as Record<string, string>;

  const sort = queryParams.sort
    ?.map((sort) => {
      return {
        key: sort.key,
        value: sort.value ? orderMap[sort.value] : undefined,
      };
    })
    .find((sort) => sort.value);

  return sort;
}

export function mapToFilterables(
  queryParamsFilters: NonNullable<TableQueryParams['filter']>,
  columnKeysToPlurals: Record<string, string> = {},
) {
  const filters: string[] = [];

  for (const { key, value } of queryParamsFilters) {
    const pluralKey = columnKeysToPlurals[key];

    if (pluralKey) {
      const values = Array.isArray(value) ? value : [value];

      for (const v of values) {
        filters.push(`filters[${pluralKey}][]=${v}`);
      }
    } else {
      filters.push(`filters[${key}]=${value}`);
    }
  }

  return filters;
}

export function prepareQueryParams(
  queryParams: TableQueryParams,
  mapFilters: (filters: NonNullable<TableQueryParams['filter']>) => string[] = (queryParamsFilters) =>
    mapToFilterables(queryParamsFilters),
) {
  const page = queryParams.pageIndex;
  const size = queryParams.pageSize;
  const search = queryParams.search;
  const sort = mapToSort(queryParams);

  return [
    search ? `search=${search}` : undefined,
    page ? `page=${page}` : undefined,
    size ? `size=${size}` : undefined,
    sort?.key ? `sort=${sort.key}` : undefined,
    sort?.value ? `order=${sort.value}` : undefined,
    queryParams.filter ? mapFilters(queryParams.filter).join('&') : undefined,
  ]
    .filter(Boolean)
    .join('&');
}
